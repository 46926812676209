<template>
  <v-container class="py-6">
    <LocaleSwitcher />
    <v-sheet class="mx-auto my-5" max-width="95%">
      <div class="text-center" v-if="loading === true">
        <v-progress-circular
          :size="70"
          :width="10"
          color="blue"
          indeterminate
        />
        <br />
        {{ $t("お客様の情報を確認中です。処理が完了するまでお待ちください。") }}
      </div>
    </v-sheet>

    <div class="text-center" v-if="loading === false">
      <h3>{{ order.clinic_name }} {{ $t("購入手続き") }}</h3>
      <v-alert
        v-if="order.is_coupon"
        style="background-color: coral"
        type="success"
      >
        {{ order.discount }}{{ $t("円") }}{{ $t("割引クーポン適用中") }}
      </v-alert>

      <v-alert
        v-if="order.is_firstcoupon"
        style="background-color: coral; margin: 10px"
        type="success"
      >
        {{ $t("初めて割引") }}{{ order.discount }}{{ $t("円分適用中") }}
      </v-alert>

      <template>
        <div>
          <v-alert :value="alert" color="red" type="success">
            {{ alert_meeage }}
          </v-alert>
        </div>
      </template>
      <form-wizard
        v-if="this.order"
        ref="wizard"
        shape="circle"
        color="#20a0ff"
        error-color="#ff4949"
        @on-complete="gmo_payment"
        title=""
        subtitle=""
        :nextButtonText="$t('次へ')"
        :backButtonText="$t('戻る')"
        :finishButtonText="$t('お支払い画面を表示')"
      >
        <!-- 入力タブ -->
        <tab-content
          :title="$t('ご発送先')"
          :before-change="() => beforeTabSwitch(1)"
        >
          <v-row v-if="this.$store.state.addressModule.data !== undefined">
            <v-col>
              {{ $t("発送先履歴で入力") }}
              <v-list-item
                v-for="temp_address in this.$store.state.addressModule.data"
                :key="temp_address.id"
                @click="select_address(temp_address)"
              >
                <v-list-item-icon>
                  <v-icon>mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  {{ temp_address.name }} {{ $t("様") }}<br />
                  {{ temp_address.postcode }}
                  {{ temp_address.address }}
                </v-list-item-content>
                <v-list-item-icon @click="delete_address(temp_address)">
                  <v-icon>mdi-delete</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider></v-divider>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="small-text">{{
                $t("郵便番号で住所を検索します")
              }}</span>
              <v-text-field :rules="[]" :label="$t('郵便番号 例0001234')"
              v-model="postcode" class="inputPostcode" autofocus outlined: true
              counter="7" prepend-inner-icon="mdi-home-search-outline"
              type="number" />
              <div v-if="is_not_found" class="v-messages__message">
                {{
                  $t("住所が見つかりません。郵便番号を正しく入力してください")
                }}
              </div>
              <vue-form-generator
                v-if="is_address"
                :schema="address_schema"
                :model="address_model"
                :options="formOptions"
              >
              </vue-form-generator>
              <div v-if="is_address">
                <v-btn
                  outlined
                  color="success"
                  elevation="2"
                  v-on:click="address_increment()"
                >
                  <v-icon left>mdi-account-plus-outline</v-icon>
                  {{ $t("住所を登録") }}
                </v-btn>

                <div class="small-text">
                  {{
                    $t(
                      "「住所を登録」いただくと、次回購入の際に再入力の必要がありません"
                    )
                  }}
                </div>
              </div>
            </v-col>
          </v-row>
          <div class="text-center" v-if="loading === true">
            <v-progress-circular
              :size="70"
              :width="10"
              color="blue"
              indeterminate
            />
            <br />
            {{
              $t("お客様の情報を確認中です。処理が完了するまでお待ちください。")
            }}
          </div>
        </tab-content>
        <!-- 確認タブ -->
        <tab-content
          :title="$t('ご確認')"
          :before-change="() => beforeTabSwitch(2)"
        >
          {{ $t("ご確認ください") }}
          <v-simple-table>
            <tr>
              <td>{{ $t("お宛名") }}</td>
              <td>{{ address_model.name }}</td>
            </tr>
            <tr>
              <td>{{ $t("郵便番号") }}</td>
              <td>{{ address_model.postcode }}</td>
            </tr>
            <tr>
              <td>{{ $t("ご住所") }}</td>
              <td>{{ address_model.address }}</td>
            </tr>
            <tr>
              <td>{{ $t("メールアドレス") }}</td>
              <td>{{ address_model.email }}</td>
            </tr>
            <tr>
              <td>{{ $t("携帯電話") }}</td>
              <td>{{ address_model.cellnumber }}</td>
            </tr>

            <tr v-if="delivery_method_model.delivery_method !== ''">
              <td>{{ $t("送付方法") }}</td>
              <td>{{ delivery_method_model.delivery_method }}</td>
            </tr>

            <tr v-if="maker_model.delivery">
              <td>{{ $t("届け先区分") }}</td>
              <td>{{ maker_model.delivery }}</td>
            </tr>

            <tr v-if="maker_model.arrival_time">
              <td>{{ $t("時間帯") }}</td>
              <td>{{ maker_model.arrival_time }}</td>
            </tr>
          </v-simple-table>

          <v-divider></v-divider>
          <p>{{ $t("ご注文内容") }}</p>
          <div v-if="r_manufacture">
            <p>
              <v-icon color="blue" class="ma-2">mdi-eye</v-icon>{{ $t("右眼") }}
            </p>
            <v-simple-table>
              <tbody>
                <tr v-if="order.r_product_name !== null">
                  <td>{{ $t("製品") }}</td>
                  <td>{{ order.r_product_name }}</td>
                </tr>
                <tr
                  v-if="
                    order.right_count !== null && 0 !== order.right_count.length
                  "
                >
                  <td>{{ $t("注文数") }}</td>
                  <td>
                    {{ order.r_price_sale.toLocaleString() }}{{ $t("円") }} ×
                    {{ order.right_count }}{{ $t("箱") }}
                  </td>
                </tr>
                <tr
                  v-if="
                    order.right_count !== null && 0 !== order.right_count.length
                  "
                >
                  <td>{{ $t("金額（税込）") }}</td>
                  <td>
                    {{ order.r_sub_total.toLocaleString() }}{{ $t("円") }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>

          <div v-if="l_manufacture">
            <p>
              <v-icon color="blue" class="ma-2">mdi-eye</v-icon>{{ $t("左眼") }}
            </p>
            <v-simple-table>
              <tbody>
                <tr v-if="order.l_product_name !== null">
                  <td>{{ $t("製品") }}</td>
                  <td>{{ order.l_product_name }}</td>
                </tr>
                <tr
                  v-if="
                    order.left_count !== null && 0 !== order.left_count.length
                  "
                >
                  <td>{{ $t("注文数") }}</td>
                  <td>
                    {{ order.l_price_sale.toLocaleString() }}{{ $t("円") }} ×
                    {{ order.left_count }}{{ $t("箱") }}
                  </td>
                </tr>
                <tr
                  v-if="
                    order.left_count !== null && 0 !== order.left_count.length
                  "
                >
                  <td>{{ $t("金額（税込）") }}</td>
                  <td>
                    {{ order.l_sub_total.toLocaleString() }}{{ $t("円") }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
          <v-simple-table>
            <tbody>
              <tr>
                <td>{{ $t("小計") }}</td>
                <td>
                  {{ (order.r_sub_total + order.l_sub_total).toLocaleString()
                  }}{{ $t("円") }}
                </td>
              </tr>
              <tr v-if="order.delivery_fee == 0">
                <td>{{ $t("送料") }}</td>
                <td>{{ $t("無料") }}</td>
              </tr>
              <tr v-if="order.delivery_fee > 0">
                <td>{{ $t("送料") }}</td>
                <td>
                  {{ order.delivery_fee.toLocaleString() }}{{ $t("円(税込)") }}
                </td>
              </tr>
              <tr>
                <td>{{ $t("合計金額（税込）") }}</td>
                <td>{{ toral_price.toLocaleString() }}{{ $t("円") }}</td>
              </tr>
            </tbody>
          </v-simple-table>

          <v-chip
            v-if="this.order.is_coupon"
            class="ma-2"
            color="pink"
            large
            label
            text-color="white"
          >
            <v-icon left> mdi-label </v-icon>
            {{ this.order.coupon.value }}{{ this.order.coupon.type
            }}{{ $t("割引クーポン適用") }}
            <span v-if="order.coupon.type == '%'"
              >{{ this.order.discount }}{{ $t("円") }}{{ $t("割引中") }}</span
            >
          </v-chip>
        </tab-content>
        <!-- 支払いタブ-->
        <tab-content :title="$t('お支払い')">
          <v-card>
            <v-card-text>
              <h2>{{ $t("お支払い") }}</h2>
            </v-card-text>
            <v-card-text>
              <p>
                {{ $t("お支払いは各種クレジットカードがご利用いただけます。") }}
              </p>
              {{ $t("決済代行サービス") }}: {{ clinic.payment_method }}<br />
              {{
                $t(
                  "決済画面から決済方法を選択し決済を完了後、「サイトに戻る」ボタンでお戻りください。"
                )
              }}<br />
            </v-card-text>

            <div class="text-center" v-if="loading === true">
              <v-progress-circular
                :size="70"
                :width="10"
                color="blue"
                indeterminate
              />
              <br />
              {{
                $t(
                  "お客様の情報を確認中です。処理が完了するまでお待ちください。"
                )
              }}
            </div>
          </v-card>
        </tab-content>
      </form-wizard>
      <v-btn
        outlined
        color="indigo"
        elevation="2"
        large
        v-on:click="go_priscription()"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        {{ $t("処方箋へ戻る") }}
      </v-btn>
    </div>
  </v-container>
</template>

<script>
/* eslint-disable */
import VueFormGenerator from "vue-form-generator";
import "vue-form-generator/dist/vfg.css";
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { GoqZipCode } from "goqzipcode";
import address from "@/mixins/address";
import maker from "@/mixins/maker";
import delivery from "@/mixins/delivery_method";
import paymentForm from "./paymentForm.vue";
import LocaleSwitcher from "@/components/LocaleSwitcher";
VueFormGenerator.validators.resources.fieldIsRequired = "必須項目です。";
import { Firebase } from "../config/firebase";

//component javascript
export default {
  name: "Checkout",
  mixins: [address, maker, delivery],
  components: {
    "vue-form-generator": VueFormGenerator.component,
    FormWizard,
    TabContent,
    paymentForm,
    order: {},
    LocaleSwitcher,
  },
  data() {
    return {
      loading: true,
      loading_customer: true,
      alert_meeage: "",
      is_checkout: true,
      is_arrival_time: true,
      alert: false,
      alert_meeage: "",
      postcode: "",
      delivery_method_names: [],
      msg: "Welcome to Your Vue.js App",
      checkout: false,
      showModal: false,
      goqZipCode: new GoqZipCode(),
      valid: false,
      is_address: false,
      is_not_found: false,
      seller: {},
      makers: [],
    };
  },
  computed: {
    order_id() {
      return this.$route.params.id;
    },
    clinic_id() {
      return this.order.clinic_id;
    },
    saler_id() {
      return this.order.saler_id;
    },
    patient_id() {
      return this.order.patient_id;
    },
    priscription_id() {
      return this.$route.query.priscription_id;
    },
    l_manufacture_id() {
      return this.order.l_manufacture_id;
    },
    r_manufacture_id() {
      return this.order.r_manufacture_id;
    },
    r_manufacture() {
      if (!this.order.r_product_name) {
        return false;
      } else {
        return this.$store.state.manufactureModule.data[this.r_manufacture_id];
      }
    },
    l_manufacture() {
      if (!this.order.r_product_name) {
        return false;
      } else {
        return this.$store.state.manufactureModule.data[this.l_manufacture_id];
      }
    },
    order() {
      return this.$store.state.orderModule.data[this.order_id];
    },
    clinic() {
      return this.$store.state.clinicModule.data[this.clinic_id];
    },
    r_productName() {
      return this.order.r_product_name;
    },
    right_count() {
      return this.order.right_count;
    },
    right_price() {
      return this.order.r_price_sale;
    },

    l_productName() {
      return this.order.l_product_name;
    },
    left_count() {
      return this.order.right_count;
    },
    left_price() {
      return this.order.l_price_sale;
    },
    sub_total_price() {
      return (
        this.left_price * this.left_count + this.right_price * this.right_count
      );
    },
    toral_price() {
      if (this.order.coupon) {
        if (this.order.coupon.type == "円") {
          return this.order.toral_price;
        }
      }
      return this.order.toral_price;
    },
    delivery_fee() {
      let temp_shipping_fee = 0;
      if (this.sub_total_price == 0) {
        temp_shipping_fee = 0;
      } else if (this.sub_total_price < this.clinic.carriage_low) {
        temp_shipping_fee = this.clinic.carriage;
      } else if (this.sub_total_price != 0) {
        let temp_shipping_fee_right = 0;
        let temp_shipping_fee_left = 0;
        let temp_maker_right = "";
        let temp_maker_left = "";
        for (let i = 0; i < this.seller.makers.length; i++) {
          const maker = this.seller.makers[i];
          // 右目の送料をチェック
          if (
            this.r_manufacture.maker_id == maker.name &&
            this.right_count != 0
          ) {
            temp_shipping_fee_right = maker.shipping_fee;
            temp_maker_right = maker.name;
          } // 左目の送料をチェック
          if (
            this.r_manufacture.maker_id == maker.name &&
            this.left_count != 0
          ) {
            temp_shipping_fee_left = maker.shipping_fee;
            temp_maker_left = maker.name;
          }
        }
        if (temp_maker_right != temp_maker_left) {
          temp_shipping_fee = temp_shipping_fee_right + temp_shipping_fee_left;
        } else {
          if (this.right_count != 0) {
            temp_shipping_fee = temp_shipping_fee_right;
          } else if (this.left_count != 0) {
            temp_shipping_fee = temp_shipping_fee_left;
          } else {
            temp_shipping_fee = 0;
          }
        }
      } else {
        temp_shipping_fee = 0;
      }
      return temp_shipping_fee;
    },
    transformed_notice() {
      return this.maker_model.delivery_notice;
    },

    validationErrors() {
      if (this.$refs.form && this.$refs.form.errors) {
        return this.$refs.form.errors;
      }
      return [];
    },
  },
  created: function () {
    this.fetchUser();
  },
  mounted: function () {},
  watch: {
    showModal: function () {
      this.$emit("showModal", this.showModal);
    },
    postcode: async function (next, prev) {
      if (prev != null && prev != undefined && prev != "") {
        if (next.length == 7) {
          this.address_model.postcode = next;
          await this.goqZipCode
            .searchAddressFromZipcode({
              zipcode: next,
              is_exact: false,
            })
            .then((result) => {
              let temp_address;
              if (result[0].pref !== undefined) {
                temp_address = result[0].pref;
              }
              if (result[0].city !== undefined) {
                temp_address = temp_address + result[0].city;
              }
              if (result[0].town !== undefined) {
                temp_address = temp_address + result[0].town;
              }
              this.address_model.address = temp_address;
              this.is_address = true;
              this.is_not_found = false;
            })
            .catch((e) => {
              // do failure handling
              this.address_model.address = "該当する住所はありません";
              this.is_address = false;
              this.is_not_found = true;
            });
        }
      }
    },
  },
  methods: {
    requiredRule() {
      return !!this.postcode || "郵便番号は必須です";
    },
    maxLengthRule() {
      return (
        (this.postcode && this.postcode.length <= 7) ||
        '郵便番号は7桁の数字です。"-"ハイフンは不要です'
      );
    },
    go_priscription: function () {
      this.$router.push(
        "/prescription/" +
          this.order.priscription_id +
          "?patient_id=" +
          this.order.patient_id
      );
    },
    fetchClinic: async function () {
      await this.$store.dispatch(
        "clinicModule/fetchById",
        this.order.clinic_id
      );
    },
    fetchSeller: async function () {
      const sellerRef = await Firebase.firestore()
        .collection("Sellers")
        .doc(this.order.saler_id);
      await sellerRef.get().then((doc) => {
        if (doc.exists) {
          this.seller = doc.data();
          this.seller.id = doc.id;
          this.makers = this.seller.makers;
        } else {
          console.log("No Seller document!");
        }
        this.error = true;
      });
    },
    fetchUser: async function () {
      await this.$store.dispatch(
        "userModule/fetchById",
        this.$store.getters.user.uid
      );
      this.fetchOrder();
    },
    //Imput Address Method Satrt===================================================
    fetchOrder: async function () {
      await this.$store.dispatch("orderModule/fetchById", this.order_id);
      await this.fetchClinic();
      await this.fetchSeller();
      const where = [["patientId", "==", this.order.patient_id]];
      this.$store.commit("addressModule/RESET_VUEX_EASY_FIRESTORE_STATE");
      const orderBy = ["created_at"];
      await this.addressFetchAndAdd(where, orderBy);

      this.fetchManufacture();
      this.loading = false;
      this.address_model.name = this.order.patient_name;

      this.address_model.cellnumber = this.order.phoneNumber;
      this.address_model.email = this.order.email;
      this.address_model.cellnumber = this.order.cellnumber;
    },
    fetchMakerById: async function (id) {
      await this.$store.dispatch("makerModule/fetchById", id);
      this.maker_model.delivery_to =
        this.$store.state.makerModule.data[id].delivery_to;
      this.maker_model.arrival_times =
        this.$store.state.makerModule.data[id].arrival_times;
      this.maker_model.delivery_notice =
        this.$store.state.makerModule.data[id].delivery_notice;
      this.delivery_method_model.delivery_methods =
        this.$store.state.makerModule.data[id].delivery_methods;

      for (const element of this.delivery_method_model.delivery_methods) {
        this.delivery_method_names.push(element.name);
      }
    },
    fetchManufacture: async function () {
      await this.$store.dispatch(
        "manufactureModule/fetchById",
        this.r_manufacture_id
      );
      await this.$store.dispatch(
        "manufactureModule/fetchById",
        this.l_manufacture_id
      );
    },

    onComplete: function () {
      this.increment();
    },
    addressFetchAndAdd: async function (where, orderBy) {
      this.$store.commit("addressModule/RESET_VUEX_EASY_FIRESTORE_STATE");
      let fetchResult = await this.$store.dispatch(
        "addressModule/fetchAndAdd",
        { clauses: { where, orderBy } }
      );
      if (fetchResult.done === true) return "all done!";
      return "retrieved 50 docs, call again to fetch the next!";
    },
    address_increment: async function () {
      if (
        this.address_model.name == "" ||
        this.address_model.address == "" ||
        this.address_model.postcode == "" ||
        this.address_model.email == "" ||
        this.address_model.cellnumber == ""
      ) {
        alert("必須項目に未入力があります");
      } else {
        await this.$store.dispatch("addressModule/set", {
          patientId: this.order.patient_id,
          name: this.address_model.name,
          address: this.address_model.address,
          postcode: this.address_model.postcode,
          email: this.order.email,
          cellnumber: this.address_model.cellnumber,
          status: "allowed",
        });
        const where = [["patientId", "==", this.order.patient_id]];
        const orderBy = ["created_at"];
        this.addressFetchAndAdd(where, orderBy);
      }
    },
    increment: async function () {
      if (
        this.address_model.name == "" ||
        this.address_model.address == "" ||
        this.address_model.postcode == "" ||
        this.address_model.email == "" ||
        this.address_model.cellnumber == "" ||
        this.order.arrival_time == "" ||
        (this.order.delivery == this.maker_model.delivery) == "" ||
        (this.order.delivery_method ==
          this.delivery_method_model.delivery_method) ==
          ""
      ) {
        alert("必須項目に未入力があります");
      } else {
        await this.$store.dispatch("addressModule/set", {
          patientId: this.order.patient_id,
          name: this.address_model.name,
          address: this.address_model.address,
          postcode: this.address_model.postcode,
          email: this.order.email,
          cellnumber: this.address_model.cellnumber,
          status: "allowed",
        });
        const where = [["patientId", "==", this.address_model.patientId]];
        const orderBy = ["created_at"];
        this.addressFetchAndAdd(where, orderBy);
      }
    },

    onMakerModelUpdated: function (newVal) {
      for (const element of this.delivery_method_model.delivery_methods) {
        if (element.name === newVal) {
          if (element.is_arrival_at) {
            this.is_arrival_time = true;
          } else {
            this.is_arrival_time = false;
          }
        }
      }
    },
    select_address: function (address) {
      this.address_model.patientId = this.order.patient_id;
      this.address_model.name = address.name;
      this.address_model.postcode = address.postcode;
      this.address_model.address = address.address;
      this.address_model.cellnumber = address.phonenumber;
      this.address_model.cellnumber = address.cellnumber;
      this.is_address = true;
    },
    //Imput Address Method End===================================================
    delete_address: async function (address) {
      const id = address.id;
      await this.$store.dispatch("addressModule/delete", id);
    },
    beforeTabSwitch: async function (value) {
      if (value === 1) {
        let check = true;
        if (this.delivery_method_model.delivery_method !== "") {
          if (
            this.address_model.name == "" ||
            this.address_model.address == "" ||
            this.address_model.postcode == "" ||
            this.address_model.email == "" ||
            this.address_model.cellnumber == "" ||
            this.maker_model.delivery == "" ||
            this.maker_model.arrival_time == "" ||
            this.delivery_method_model.delivery_method == ""
          ) {
            check = false;
          } else {
            this.checkout = true;
            this.order.name = this.address_model.name;
            this.order.address = this.address_model.address;
            this.order.postcode = this.address_model.postcode;
            this.order.cellnumber = this.address_model.cellnumber;
            this.order.email == this.address_model.email;
            this.order.arrival_time = this.maker_model.arrival_time;
            this.order.delivery = this.maker_model.delivery;
            this.order.delivery_method =
              this.delivery_method_model.delivery_method;
            this.order.status = "panding";
            check = true;
          }
        } else {
          if (
            this.address_model.name == "" ||
            this.address_model.address == "" ||
            this.address_model.postcode == "" ||
            this.address_model.email == "" ||
            this.address_model.cellnumber == ""
          ) {
            check = false;
          } else {
            this.checkout = true;
            this.order.name = this.address_model.name;
            this.order.address = this.address_model.address;
            this.order.postcode = this.address_model.postcode;
            this.order.cellnumber = this.address_model.cellnumber;
            this.order.arrival_time = this.maker_model.arrival_time;
            this.order.delivery = this.maker_model.delivery;
            this.order.email == this.address_model.email;
            this.order.delivery_method = "";
            this.order.status = "panding";
            check = true;
          }
        }
        return check;
      } else if (value === 2) {
        return true;
      } else if (value === 3) {
        return true;
      } else if (value === 4) {
        return true;
      }
    },

    check_email() {
      if (this.address_model.email === undefined) {
        alert(
          "1ご連絡先メールアドレスをご入力ください" + this.address_model.email
        );
        return false;
      }
      if (this.address_model.email === null) {
        alert(
          "2ご連絡先メールアドレスをご入力ください" + this.address_model.email
        );
        return false;
      }
      if (this.address_model.email === "") {
        alert(
          "3ご連絡先メールアドレスをご入力ください" + this.address_model.email
        );
        return false;
      }
      var pattern =
        /^[A-Za-z0-9]{1}[A-Za-z0-9_+.-]*@{1}[A-Za-z0-9_.-]+.[A-Za-z0-9]+$/;
      if (pattern.test(this.address_model.email)) {
      } else {
        alert(
          "4メールアドレスを正しくご入力ください" + this.address_model.email
        );
        return false;
      }
      return true;
    },
    /* GMO PAYMENT CREATE LINK */
    async gmo_payment() {
      if (this.check_email(this.customerEmail) == false) {
        return;
      }

      this.loading = true;
      let docData = {
        Amount: this.toral_price,
        total_payment: this.toral_price,
        // CUSTOMER INFO
        patient_id: this.order.patient_id,
        patient_patientNumber: this.order.priscription.patient_patientNumber,
        phone_number: this.address_model.cellnumber,
        customerEmail: this.address_model.email,
        customerName: this.address_model.name,
        // ORDER INFO
        order_id: this.order.id,
        priscription_id: this.order.priscription_id,
        prescription_number: this.order.priscription.prescription_number,
        sales_tax: Math.round(this.toral_price * 0.1),
        // CLINIC INFO
        clinic_id: this.clinic_id,
        clinic_name: this.clinic.name,
        siteUrl: window.location.href,
        RetUrl: window.location.href,
        // DELIIVERY DEVIBARY
        delivery_name: this.address_model.name,
        delivery_address: this.address_model.address,
        delivery_postcode: this.address_model.postcode,
        delivery_cellnumber: this.address_model.cellnumber,
        delivery_arrival_time: this.maker_model.arrival_time,
        delivery_delivery: this.maker_model.delivery,
        delivery_email: this.address_model.email,
        delivery_delivery_method: this.delivery_method_model.delivery_method,
        delivery_fee: this.delivery_fee,
      };
      if (this.clinic.payment_method == "SQUARE") {
        await Firebase.firestore()
          .collection("Orders")
          .doc(this.order_id)
          .update(docData)
          .then(() => {
            this.$router.push({
              name: "square",
              params: { id: this.order_id },
            });
            return;
          });
      } else if (this.clinic.payment_method == "DGFT") {
        await Firebase.firestore()
          .collection("Orders")
          .doc(this.order_id)
          .update(docData);
        this.implemetCollections(docData);
      } else {
        this.implemetCollections(docData);
      }
    },

    implemetCollections(params) {
      const functions = Firebase.app().functions("asia-northeast1");
      if (this.clinic.payment_method == "GMO") {
        const httpEvent = functions.httpsCallable("gmoCollectionInfo");
        httpEvent(params).then((res) => {
          this.OrderID = res.data.orderID;
          this.getRetUrl = res.data.resurl;
          window.location.href = this.getRetUrl;
        });
      } else if (this.clinic.payment_method == "STRIPE") {
        const httpEvent = functions.httpsCallable("stripCollectionInfo");
        httpEvent(params).then((res) => {
          this.OrderID = res.data.orderID;
          this.getRetUrl = res.data.resurl;
          window.location.href = this.getRetUrl;
        });
      } else if (this.clinic.payment_method == "DGFT") {
        const httpEvent = functions.httpsCallable("dgftCollectionInfo");
        httpEvent(params).then((res) => {
          console.log(res);
          if (res.data.status == "success") {
            this.$router.push({
              name: "dgft",
              params: {
                order_id: this.order_id,
                payment_key: res.data.payment_key,
              },
            });
          } else {
            alert(
              "エラー：決済画面を表示できません。code: " +
                res.data.result_code +
                "サポートに連絡してください。電話：050-3637-3860"
            );
            this.loading = false;
            //window.location.href = "/";
            return;
          }
        });
      } else {
        alert(
          "エラー：決済画面を表示できません。サポートに連絡してください。電話：050-3637-3860"
        );
        this.loading = false;
        window.location.href = "/";
        return;
      }
    },
  },
};

/* eslint-enable */
</script>
<style>
.vue-form-generator {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-top: 0;
  padding-top: 0;
}
fieldset {
  border: 1;
  padding: 1em;
  margin: 0.5em;
  width: 100%;
  border-style: solid;
  border-color: rgb(170, 170, 170);
  border-radius: 5px;
}
.small-text {
  font-size: small;
}
.v-messages__message {
  color: red;
}

.inputPostcode {
  margin-bottom: 0;
  padding-bottom: 0;
}
</style>
